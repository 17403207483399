import React from "react";

const ColorSwitcher = () => {


    const handleClick = () => {

        const currentColor = document.documentElement.style.getPropertyValue('--maincolor');
        const secondColor = document.documentElement.style.getPropertyValue('--secondcolor');

        //const newColor = currentColor === '#ff87c3' ? '#48dd90' : '#ff87c3';

        let newColor;

        if (currentColor === '#ff87c3') {
            newColor = '#87ffc3'
        } else {
            newColor = '#ff87c3'
        }

        document.documentElement.style.setProperty('--maincolor', newColor);

        const colorSwitcher = document.querySelector('.color-switcher');
        // const nextColor = currentColor === '#ff87c3' ? '#ff87c3' : '#87ffc3';
        colorSwitcher.innerHTML = newColor
    };

    return (
        <div className="color-switcher" onClick={handleClick}>#ff87c3</div>
    )
};


export default ColorSwitcher;