import React, { useEffect, useState } from 'react'
import imagesWebsite from '../assets/database/images-website.js'

const Sections = () => {

    const [imageDimensions, setImageDimensions] = useState({});

    const handleImageLoad = (websiteKey, index, event) => {
        const { naturalWidth, naturalHeight } = event.target;
        setImageDimensions(prevDimensions => ({
            ...prevDimensions,
            [`${websiteKey}_${index}`]: { width: naturalWidth, height: naturalHeight }
        }));
    };

    useEffect(() => {
        const liItems = document.querySelectorAll('li');

        liItems.forEach((li) => {
            // Hover effect to highlight the menu item and scroll to the section
            li.addEventListener('mouseover', () => {
                liItems.forEach((li) => li.classList.remove('li_selected'));
                li.classList.add('li_selected');

                const sectionId = li.getAttribute('data-section');
                activateMenuLink(sectionId);
                scrollToSection(sectionId); // Scroll to the section on hover
            });

            // Click effect to directly scroll to the section
            li.addEventListener('click', () => {
                const sectionId = li.getAttribute('data-section');
                scrollToSection(sectionId);
            });
        });

        function activateMenuLink(sectionId) {
            document.querySelectorAll('#menu li').forEach((link) => {
                link.classList.toggle('li_selected', link.getAttribute('data-section') === sectionId);
            });
        }

        function scrollToSection(sectionId) {
            const sectionsContainer = document.getElementById('sections');
            const sectionToShow = document.querySelector(`section#${sectionId}`);
            if (sectionToShow) {
                const scrollTop = sectionToShow.offsetTop;
                sectionsContainer.scrollTo({
                    top: scrollTop,
                    behavior: 'smooth' // Smooth scroll on hover
                });
            }
        }


        // Options for the Intersection Observer
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        // Callback for the Intersection Observer
        function observerCallback(entries) {
            entries.forEach((entry) => {

                if (entry.isIntersecting) {
                    // Get the section ID
                    const sectionId = entry.target.getAttribute('data-section-id');

                    // Activate the corresponding menu link
                    activateMenuLink(sectionId);
                }
            });
        }

        // Create the Intersection Observer
        const observer = new IntersectionObserver(observerCallback, observerOptions);

        // Observe each section
        document.querySelectorAll('section').forEach((section) => {
            observer.observe(section);
        });

    }, []);
    /* 
        const [shuffledKeys, setShuffledKeys] = useState([]);
    
    useEffect(() => {
      const websiteKeys = Object.keys(imagesWebsite);
      const shuffledKeys = websiteKeys.sort(() => Math.random() - 0.5);
      setShuffledKeys(shuffledKeys);
    }, [imagesWebsite]);
     */

    return (
        <main>
            <div id="sections">
                {Object.keys(imagesWebsite).map((websiteKey) => (
                    <section id={websiteKey} data-section-id={websiteKey} key={websiteKey}>

                        {imagesWebsite[websiteKey].pictures.map((picture, picIndex) => (
                            <a href={imagesWebsite[websiteKey].website} target='_blank' rel="noreferrer" key={`${websiteKey}-${picIndex}`}>
                                <picture>
                                    <source type="image/webp" srcSet={picture.srcWebp}>
                                    </source>
                                    <img src={picture.srcJpeg}
                                        alt={`${websiteKey} website`}
                                        width={imageDimensions[`${websiteKey}_${picIndex}`]?.width || 'auto'}
                                        height={imageDimensions[`${websiteKey}_${picIndex}`]?.height || 'auto'}
                                        onLoad={(event) => handleImageLoad(websiteKey, picIndex, event)} />

                                </picture>
                            </a>
                        ))}

                        <p className="website-url">
                            <a target="_blank" rel="noreferrer" href={imagesWebsite[websiteKey].website}>{imagesWebsite[websiteKey].website02}</a>
                        </p>

                    </section>
                ))}

                <section id="contact" data-section-id="contact" key="contact">
                    <p className="bio">
                        {/*  Fascinated by the endless possibilities offered by the web, I continually push the boundaries of modern front-end features, designing and developing interfaces from scratch using technologies like JavaScript, React, and Three.js<br /><br />

                        My work ranges from straightforwardly informative sites, following the current UX/UI functionality and speed principles,

                        to more conceptual visual projects, often going against those rules. The later, focussing on interactivtiy, randomization or 3D, will often require more time from the user to figure them out. */}

                        Fascinated by the endless possibilities offered by the web, I play with the boundaries of front-end development by designing and developing interfaces using technologies such as Javascript, React, and Three.js.<br /><br />
                        I produce works ranging from informative websites - aligned with current UX/UI conventions - to more conceptual and visually driven projects, often breaking traditional functionality or color norms. The latter, focusing on interactivity, randomization or 3D elements, typically requires more time for users to explore, purposely slowing down the absorption of information.{/*  Like a game, a little head scratching is all you need. */}

                        <br /><br />Clinical lines and synthetic colors infused with a touch of playfulness: drop me a line at <span className="mainColor">info@ff87c3.eu</span> if you have any digital fantasy.<br /><br />Programmatically made in Berlin.

                    </p>
                    <p className="website-url">
                        <a href="mailto:info@ff87c3.eu">info@ff87c3.eu</a>
                    </p>
                </section>
            </div>

            <ul id="menu">
                {Object.keys(imagesWebsite).map((websiteKey) => (
                    <li data-section={websiteKey}
                        key={websiteKey}
                    >
                        <a href={`#${websiteKey}`}>{imagesWebsite[websiteKey].title}</a>
                    </li>
                ))}

                <li data-section="contact" key="854dfgfg"> <a href="#contact">info</a></li>
            </ul>
        </main>
    )
}

export default Sections


